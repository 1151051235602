import {DateTimeUtils} from '../Infrastructure/DateTime/DateTimeUtils';
import ReactHighcharts from 'react-highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import highchartsExporting from 'highcharts/modules/exporting';
import React, {MutableRefObject, useEffect, useState} from 'react';
import {SensorTiltDeviation, SensorTiltValue} from '../../common/types/SensorTiltValue';
import {Button} from 'antd';
import {Trans} from 'react-i18next';
import {TiltDetection} from '../../common/types/DbModel/SensorsAllInformationsViewRow';
import {PLOT_LINE_COLORS, SENSOR_ISSUE_ALARMS} from '../../components/Shared/Constants/Chart';
import PrepareDescText from '../../components/Analysis/Chart/ExtensionMethods/PrepareDescText';

NoDataToDisplay(ReactHighcharts.Highcharts);
highchartsExporting(ReactHighcharts.Highcharts);

type TiltGraphProps = {
	tiltProfile: TiltDetection;
	tiltValues: SensorTiltValue[];
	tiltDeviations: SensorTiltDeviation[];
	filterActive: boolean;
	handleChartSelection: (event: {trigger: any; userMin: any; userMax: any}) => void;
	resetZoom: () => void;
	forwardRef: MutableRefObject<any>;
};

export function TiltGraph(props: TiltGraphProps): React.JSX.Element {
	const [sensorTiltValues, setSensorTiltValues] = useState(tiltDataToSeries(props.tiltValues));

	let visible = props.tiltDeviations.length > 0;

	useEffect(() => {
		setSensorTiltValues(tiltDataToSeries(props.tiltValues));
	}, [props.tiltValues]);

	useEffect(() => {
		if (props.tiltDeviations.length > 0) {
			setSensorTiltValues(prevValues =>
				[
					...prevValues,
					...props.tiltDeviations.map(deviation => [
						DateTimeUtils.dayjsToHighchartsTimestamp(deviation.timestamp),
						deviation.tiltAngle,
					]),
				].sort((a, b) => a[0] - b[0])
			);
		}
	}, [props.tiltDeviations]);

	function getDeviationPlotlines() {
		return props.tiltDeviations.map(deviation => createPlotLine(deviation));
	}

	function createPlotLine(deviation: SensorTiltDeviation) {
		const timestamp = DateTimeUtils.dayjsToHighchartsTimestamp(deviation.timestamp);
		return {
			color: PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS,
			width: 2,
			value: timestamp,
			label: createPlotLineLabel(),
			events: createPlotLineEvents(deviation, timestamp),
		};
	}

	function createPlotLineLabel() {
		return {
			text: '',
			verticalAlign: 'top',
			rotation: 0,
			align: 'center',
			useHTML: true,
			style: {
				color: 'black',
				fontWeight: 'bold',
				backgroundColor: 'rgba(232, 252, 251, .6)',
			},
		};
	}

	function createPlotLineEvents(deviation: SensorTiltDeviation, timestamp: number) {
		return {
			mouseenter: () => handleMouseEnter(deviation, timestamp),
			mouseout: () => handleMouseOut(timestamp),
		};
	}

	function handleMouseEnter(deviation: SensorTiltDeviation, timestamp: number) {
		const plotLine = findPlotLine(timestamp);
		if (plotLine) {
			plotLine.svgElem.attr('stroke-width', plotLine.svgElem.attr('stroke-width') + 2);
			plotLine.label.attr(
				'text',
				PrepareDescText(deviation.timestamp.format('DD.MMM YYYY HH:mm'), `Tilt Warning: ${deviation.tiltAngle}°`)
			);
		}
	}

	function handleMouseOut(timestamp: number) {
		const plotLine = findPlotLine(timestamp);
		if (plotLine) {
			plotLine.svgElem.attr('stroke-width', plotLine.svgElem.attr('stroke-width') - 2);
			plotLine.label.attr('text', '');
		}
	}

	function findPlotLine(timestamp: number) {
		return props.forwardRef.current.chart.xAxis[0].plotLinesAndBands.find((line: any) => line.options.value === timestamp);
	}

	function tiltDataToSeries(tiltData: SensorTiltValue[]) {
		if (!Array.isArray(tiltData)) {
			return [];
		}

		return tiltData.map(t => [DateTimeUtils.dayjsToHighchartsTimestamp(t.Timestamp), t.TiltAngle]);
	}

	const chartOptions = {
		credits: {enabled: false},
		exporting: {enabled: false},
		chart: {
			zoomType: 'x',
			height: 400,
			borderRadius: 0,
			spacing: [5, 20, 20, 0],
		},
		time: {
			timezoneOffset: -DateTimeUtils.getCurrentUserTZOffset(),
		},
		title: {text: 'Tilt angle'},
		xAxis: {
			type: 'datetime',
			labels: {
				format: '{value:%d.%b.%Y %H:%M}',
				staggerLines: 2,
				y: 20,
				x: 4,
			},
			events: {
				afterSetExtremes: function (event: {trigger: any; userMin: any; userMax: any}) {
					if (typeof props.handleChartSelection === 'function' && event.trigger) {
						props.handleChartSelection(event);
					}
				}.bind(this),
			},
			maxPadding: 0.03,
			plotLines: visible ? getDeviationPlotlines() : [],
		},
		yAxis: {
			labels: {
				format: '{value}°',
			},
			title: {
				text: '',
			},
			min: 0,
			max: 180,
			tickInterval: 45,
		},
		tooltip: {
			valueSuffix: '°',
			xDateFormat: '%d.%b.%Y %H:%M',
		},
		series: [
			{
				animation: false,
				showInLegend: false,
				name: 'Tilt angle',
				data: sensorTiltValues,
			},
			...(props.tiltProfile?.enable && props.tiltProfile.limit_angle !== undefined && sensorTiltValues.length > 0
				? [
						{
							name: 'Threshold',
							color: PLOT_LINE_COLORS.WARNING_THRESHOLD,
							data: [
								[sensorTiltValues[0][0], props.tiltProfile.limit_angle],
								[sensorTiltValues[sensorTiltValues.length - 1][0], props.tiltProfile.limit_angle],
							],
							enableMouseTracking: false,
							dashStyle: 'ShortDash',
							marker: {enabled: false},
							animation: false,
						},
				  ]
				: []),
			...(props.tiltDeviations.length > 0
				? [
						{
							name: SENSOR_ISSUE_ALARMS.TILT_WARNING[0],
							data: [],
							color: PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS,
							marker: {enabled: false},
							enableMouseTracking: false,
							events: {
								legendItemClick: function () {
									if (!visible) {
										getDeviationPlotlines().forEach(deviation => {
											props.forwardRef.current.chart.xAxis[0].addPlotLine(deviation);
										});
									} else {
										props.forwardRef.current.chart.xAxis[0].plotLinesAndBands.forEach(plotLine => {
											if (plotLine.options.color === PLOT_LINE_COLORS.SENSOR_ISSUE_ALARMS) {
												props.forwardRef.current.chart.xAxis[0].removePlotLine(plotLine.id);
											}
										});
									}
									visible = !visible;
									return true;
								},
							},
						},
				  ]
				: []),
		],
	};

	return (
		<>
			<div style={{marginBottom: '50px'}}>
				{props.filterActive && (
					<Button style={{float: 'right', marginRight: '20px'}} onClick={props.resetZoom}>
						<Trans i18nKey="global.resetZoom" />
					</Button>
				)}
			</div>
			<ReactHighcharts config={chartOptions} ref={props.forwardRef} />
		</>
	);
}
