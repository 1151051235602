import React, {useContext, useEffect, useRef, useState} from 'react';
import {ViewHeader} from '../Common';
import {DeviationService} from '../../common/services/DeviationService/DeviationService';
import {ContentWrapper, ViewWrapper} from '../Layout';
import {ColumnApi, GridApi, IServerSideDatasource, IServerSideGetRowsParams} from 'ag-grid-enterprise';
import {DeviationInfo} from '../../components/Services/Deviations/DeviationInfo';
import {DeviationCloumnDefinition} from './DeviationColumnDefinition';
import {AgGridWrapper} from '../../components/Shared/Components';
import {GridReadyEvent} from 'ag-grid-community';
import {AgGridReact} from 'ag-grid-react';
import {DeviationsAcknowledge} from './Views/DeviationsAcknowledge';
import {Access} from '../../components/Infrastructure/Authorization/Components';
import {accessPermissions, userRoles} from '../../components/Infrastructure/Authorization/Access';
import {authUtils} from '../../components/Infrastructure/Authorization/Utils';
import {AccessContext} from '../../components/Infrastructure/Authorization/Context/AccessContext';
import {useHistory} from 'react-router-dom';
import {GridSideBar} from '../../components/SensorListView/SideBarDefinitions';
import CountStatusBar from '../../components/SensorListView/CountStatusBar';
import {useSearchParams} from 'react-router-dom-v5-compat';
import {Button, Col} from 'antd';
import {CheckOutlined} from '@ant-design/icons';

interface GridApiRef {
	api: GridApi<DeviationInfo>;
	columnApi: ColumnApi;
}

export function Deviations(): React.JSX.Element {
	const history = useHistory();
	const gridRef = useRef(null);
	const accessContext = useContext(AccessContext);
	const [searchParams] = useSearchParams();

	const [acknowledgeModalVisible, setAcknowledgeModalVisible] = useState(false);
	const [selectedRows, setSelectedRows] = useState<DeviationInfo[]>([]);

	const cacheBlockSize = 50;
	let totalRows: number = undefined;

	useEffect(() => {
		setInitialFilterModel(searchParams.get('moduleId'), searchParams.get('type'));
		refreshViewData();
	}, [searchParams]);

	function getGridApi(): GridApiRef {
		if (!gridRef?.current) {
			return {api: undefined, columnApi: undefined};
		}
		const {api, columnApi} = gridRef.current as GridApiRef;
		if (api == null || columnApi == null) {
			return {api: undefined, columnApi: undefined};
		}

		return {api: api, columnApi: columnApi};
	}

	async function refreshViewData() {
		const {api} = getGridApi();
		api?.refreshServerSide();
	}

	async function getRowsFromServer(params: IServerSideGetRowsParams) {
		const {api} = getGridApi();

		if (totalRows && params.request.startRow >= totalRows && !params.request.filterModel && !params.request.groupKeys.length) {
			return;
		}

		let deviations = [];
		try {
			const results = await DeviationService.GetDeviations(params.request);
			deviations = results.deviations;
			totalRows = results.total;
		} catch (e) {
			if (e.data?.code === 'PGRST103') {
				api.ensureIndexVisible(0, null);
				params.request.startRow = 0;
				params.request.endRow = cacheBlockSize;
			} else {
				console.error(e);
			}
		}

		if (params.request.groupKeys.length < params.request.rowGroupCols.length) {
			const groupColIds = params.request.rowGroupCols.map(col => col.id);
			deviations = getGroupRows(deviations, groupColIds);
			totalRows = deviations.length;
		}

		params.success({
			rowData: deviations,
			rowCount: totalRows * 1,
		});
	}

	function getGroupRows(deviations: any[], groupColIds: string[]): DeviationInfo[] {
		return deviations.filter((deviation, index, self) =>
			groupColIds.every(id => self.findIndex(s => s[id] === deviation[id]) === index)
		);
	}

	function getServerSideDatasource(): IServerSideDatasource {
		return {
			getRows: async params => getRowsFromServer(params),
		};
	}

	async function onGridReady(_params: GridReadyEvent) {
		const {api, columnApi} = getGridApi();
		if (!api || !columnApi) {
			return;
		}

		api.setServerSideDatasource(getServerSideDatasource());
		api.setColumnDefs(DeviationCloumnDefinition);
		setInitialFilterModel(searchParams.get('moduleId'), searchParams.get('type'));
	}

	function setInitialFilterModel(moduleId?: string, type?: string) {
		const {api} = getGridApi();
		if (!api) {
			return;
		}

		const filterModel = api.getFilterModel();
		if (moduleId) {
			filterModel['module_id'] = {
				type: 'equals',
				filter: moduleId,
			};
		} else {
			delete filterModel['module_id'];
		}

		if (type) {
			filterModel['deviation_type'] = {
				filterType: 'set',
				values: [type],
			};
		} else {
			delete filterModel['deviation_type'];
		}

		api.setFilterModel(filterModel);
	}

	function onAcknowledgeList() {
		setTimeout(() => {
			getGridApi().api.deselectAll();
			refreshViewData();
			setAcknowledgeModalVisible(false);
		}, 1000);
	}

	const hasDeviationDetailViewAccess = authUtils.findAccessElement(
		accessPermissions.deviations.child.deviationsDetail.path,
		accessContext.user.access_settings.access
	);

	function navigateToEventDetail(deviation: DeviationInfo) {
		history.push(`/deviationsDetail/${deviation.deviation_start_event_id}/${deviation.sensor_id}`);
	}

	return (
		<>
			<DeviationsAcknowledge
				title={`Acknowledge ${selectedRows.length} deviations`}
				isVisible={acknowledgeModalVisible}
				isLoading={false}
				onChangeModalVisibility={setAcknowledgeModalVisible}
				deviations={selectedRows.map((deviation: DeviationInfo) => deviation.id)}
				acknowledgeSuccess={onAcknowledgeList}
			/>
			<ViewWrapper>
				<ViewHeader heading={'deviations.view'} knowledgeHelpId={'020'}>
					<Access access={accessPermissions.deviations.child.multiAcknowledge} roles={userRoles.default}>
						<Col>
							<Button
								id="acknowledgeActiveDeviations"
								type="primary"
								size="large"
								onClick={e => setAcknowledgeModalVisible(true)}
								disabled={selectedRows.length === 0}
								icon={<CheckOutlined />}
							>
								<span>Acknowledge ({selectedRows.length})</span>
							</Button>
						</Col>
					</Access>
				</ViewHeader>
				<ContentWrapper>
					<Access access={accessPermissions.deviations.child.viewActiveDeviations} roles={userRoles.default}>
						<AgGridWrapper>
							<AgGridReact
								getRowId={params => {
									const groupColumns = params.columnApi.getRowGroupColumns();
									if (groupColumns.length > params.level) {
										return params.data[groupColumns[params.level].getColId()];
									}
									return params.data.id;
								}}
								cacheBlockSize={cacheBlockSize}
								onGridReady={onGridReady}
								rowModelType={'serverSide'}
								columnDefs={DeviationCloumnDefinition}
								ref={gridRef}
								serverSideFilterOnServer={true}
								serverSideSortOnServer={true}
								serverSideInfiniteScroll={true}
								rowSelection="multiple"
								isRowSelectable={params => params.data.is_acknowledged === false}
								suppressRowClickSelection={true}
								sideBar={GridSideBar}
								onSelectionChanged={params => setSelectedRows(params.api.getSelectedRows())}
								onRowClicked={props => {
									hasDeviationDetailViewAccess &&
										getGridApi().api.getFocusedCell().column.getColId() !== 'checkbox' &&
										navigateToEventDetail(props.data);
								}}
								statusBar={{
									statusPanels: [{statusPanel: CountStatusBar, align: 'left'}],
								}}
							/>
						</AgGridWrapper>
					</Access>
				</ContentWrapper>
			</ViewWrapper>
		</>
	);
}

export default Deviations;
