import React from 'react';
import LoadingTie from './LoadingTie';

const DeviationTiltAngleTie = (props: {tiltDeviation: {tiltAngle: any}}) => {
	return (
		<div className="card card-default" style={{height: '100%'}} id="deviationTiltAngle">
			<div className="card-body text-center">
				{props.tiltDeviation.tiltAngle ? (
					<>
						<h4>Tilt Angle</h4>
						<br />
						<h3>{props.tiltDeviation.tiltAngle}°</h3>
						<br />
					</>
				) : (
					<LoadingTie />
				)}
			</div>
		</div>
	);
};

export default DeviationTiltAngleTie;
