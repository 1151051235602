import React, {Component} from 'react';
import {Trans} from 'react-i18next';
import ContentWrapper from '../../../../Layout/ContentWrapper';
import FormValidator from '../../../../Forms/FormValidator';
import Cancel from '../../../../Shared/Components/Cancel';
import Save from '../../../../Shared/Components/Save';
import Shared from '../../../../Shared/Shared';
import {INPUT_TYPES} from '../../../../Shared/Constants/InputTypes';
import {getInitialStateData} from '../Extensions/StateCreator';
import {getIssueProfile} from '../Queries/IssueProfileQueries';
import {saveIssueProfile, updateIssueProfile} from '../Commands/IssueProfileCommands';
import {MODES} from '../Modes';
import AccordionComponent from './AccordionComponent';
import CheckBoxComponent from './CheckBoxComponent';
import SensorMappingsTable from './SensorMappingsTable';
import MissingCommunicationWarningEnum from './MissingCommunicationWarningEnum';
import ProfileNameComponent from './ProfileNameComponent';
import TiltWarningComponent from './TiltWarningComponent';
import {TILT_WARNING_INITIAL_POSITIONS} from '../../../../Shared/Constants/TiltWarning/TiltWarning';
import {DeviceUtils} from '../../../../../common/util';

interface IssueProfileState {
	issueProfile: {
		id: number;
		missing_communication_warning: boolean;
		missing_communication_warning_enum: number;
		sensor_failure_alarm: boolean;
		lost_measurement_alarm: boolean;
		low_battery_warning: boolean;
		tilt_warning: boolean;
		tilt_warning_initial_position: {reference_x: number; reference_y: number; reference_z: number};
		tilt_warning_angle: number;
		errors?: any;
	};
	mode: string;
	errors?: any;
	saveDisabled?: boolean;
	formSettings?: any;
	initialFormValues?: any;
	profileUpdate?: boolean;
	sensors?: any[];
}

class IssueProfileComponent extends Component<any, IssueProfileState> {
	constructor(props) {
		super(props);
		if (props.location.state) {
			this.state = getInitialStateData(props.location.state.mode, props.location.state.id);

			if (props.location.state.mode !== MODES.ADD) {
				this.loadIssueProfile(props.location.state.mode);
			}
		}
	}

	loadIssueProfile = (mode: string) => {
		getIssueProfile(this.state.issueProfile.id).then(issueProfile => {
			const hasLiberoGx = issueProfile.sensors?.some(s => DeviceUtils.IsLiberoGx(s.serial_number));

			issueProfile.tilt_warning_initial_position = issueProfile.tilt_warning_initial_position ?? TILT_WARNING_INITIAL_POSITIONS[0];
			issueProfile.tilt_warning_angle = issueProfile.tilt_warning_angle ?? 15;

			this.setState(prevState => ({
				...prevState,
				issueProfile,
				sensors: issueProfile.sensors,
				initialFormValues: issueProfile,
				...(mode === MODES.EDIT && {
					formSettings: {
						...prevState.formSettings,
						tiltWarningCheckboxDisabled: hasLiberoGx,
					},
				}),
			}));
		});
	};

	validateOnChange = event => {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;
		const result = FormValidator.validate(input);

		this.setState(
			{
				issueProfile: {
					...this.state.issueProfile,
					[input.name]: value,
				},
				errors: {
					...this.state.issueProfile.errors,
					[input.name]: result,
				},
			},
			() => {
				switch (this.state.mode) {
					case MODES.DISPLAY:
						this.setState({saveDisabled: true}); //Save button is disabled
					case MODES.ADD:
						this.setState({saveDisabled: false}); //Save button is enabled
						break;
					case MODES.EDIT:
						const initialValues = Shared.mapObjectValuesToString(this.state.initialFormValues);
						const currentValues = Shared.mapObjectValuesToString(this.state.issueProfile);
						this.setState({saveDisabled: JSON.stringify(initialValues) === JSON.stringify(currentValues)}); //If there is any change, then save button is enabled.
						break;
					default:
						break;
				}
			}
		);
	};

	hasError = (formName, inputName, method) => {
		return this.state.issueProfile && this.state.errors && this.state.errors[inputName] && this.state.errors[inputName][method];
	};

	onSubmit = e => {
		const form = e.target;
		const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName));

		const {errors, hasError} = FormValidator.bulkValidate(inputs);
		this.setState({errors: errors});

		this.state.issueProfile.missing_communication_warning_enum = !this.state.issueProfile.missing_communication_warning
			? 0
			: this.state.issueProfile.missing_communication_warning_enum; //State has been changed in this way to prevent overtaking problems

		if (!hasError) {
			switch (this.state.mode) {
				case MODES.DISPLAY:
				//This case is not possible since save button is disabled, when the mode is DISPLAY.
				case MODES.ADD:
					saveIssueProfile(this.state.issueProfile, this);
					break;
				case MODES.EDIT:
					updateIssueProfile(this.state.issueProfile, this);
					break;
				default:
					break;
			}
		}
		e.preventDefault();
	};

	handleIssueAlarmInputChange = event => {
		const input = event.target;
		let value = null;

		switch (input.type) {
			case INPUT_TYPES.CHECKBOX:
				value = input.checked;
				break;
			case INPUT_TYPES.NUMBER:
				value = input.valueAsNumber;
				break;
			case INPUT_TYPES.TEXT:
				value = input.value;
				break;
			case INPUT_TYPES.SELECT:
				value = parseInt(input.value);
				break;
			default:
				break;
		}

		const result = FormValidator.validate(input);

		this.setState(
			prevState => ({
				issueProfile: {
					...prevState.issueProfile,
					[input.name]: value,
					tilt_warning_initial_position:
						input.id === 'tiltWarningInitialPositionId'
							? TILT_WARNING_INITIAL_POSITIONS[value]
							: prevState.issueProfile.tilt_warning_initial_position,
				},
				errors: {
					...prevState.issueProfile.errors,
					[input.name]: result,
				},
			}),
			() => {
				if (this.state.profileUpdate) {
					const initialValues = Shared.mapObjectValuesToString(this.state.initialFormValues);
					const currentValues = Shared.mapObjectValuesToString(this.state.issueProfile);
					this.setState({saveDisabled: JSON.stringify(initialValues) === JSON.stringify(currentValues)});
				} else {
					this.setState({saveDisabled: false});
				}
			}
		);
	};

	cancel = e => {
		e.preventDefault();
		this.props.history.push('/issueProfiles');
	};

	render() {
		return (
			<ContentWrapper>
				<form id="issueProfileForm" name="issueProfileForm" onSubmit={this.onSubmit}>
					<div className="content-heading">
						<div>
							<Trans i18nKey={'settings.issueProfiles.title'} />
						</div>
						<div className="ml-auto">
							<Save disabled={this.state.saveDisabled} />
							{'\u00A0'}
							<Cancel onClick={this.cancel} />
						</div>
					</div>
					<ProfileNameComponent
						issueProfile={this.state.issueProfile}
						disabled={this.state.formSettings.profileNameInputDisabled}
						hasError={this.hasError}
						onChange={this.validateOnChange}
					/>
					<br />
					<AccordionComponent
						accordionItemid="accordionUniversalIssues"
						accordionTitleId="accordionUniversalIssuesTitle"
						transkey="settings.issueProfiles.universalIssues"
						itemBody={
							<>
								<CheckBoxComponent
									id="checkboxMissingCommunicationWarning"
									name="missing_communication_warning"
									text="Missing communication warning"
									onChange={this.handleIssueAlarmInputChange}
									checked={this.state.issueProfile.missing_communication_warning}
									disabled={this.state.formSettings.missingCommunicationWarningDisabled}
								/>
								<br />
								<MissingCommunicationWarningEnum
									issueProfile={this.state.issueProfile}
									handleIssueAlarmInputChange={this.handleIssueAlarmInputChange}
									mode={this.state.mode}
									hasError={this.hasError}
								/>
								<br />
								<CheckBoxComponent
									id="checkboxSensorFailureAlarm"
									name="sensor_failure_alarm"
									text="Sensor failure alarm"
									onChange={this.handleIssueAlarmInputChange}
									checked={this.state.issueProfile.sensor_failure_alarm}
									disabled={this.state.formSettings.sensorFailureAlarmInputDisabled}
								/>
								<br />
								<CheckBoxComponent
									id="checkboxLostMeasurementAlarm"
									name="lost_measurement_alarm"
									text={<Trans i18nKey={'settings.issueProfiles.lostMeasurementAlarm'} />}
									onChange={this.handleIssueAlarmInputChange}
									checked={this.state.issueProfile.lost_measurement_alarm}
									disabled={this.state.formSettings.lostMeasurementAlarmCheckboxDisabled}
								/>
							</>
						}
					/>
					<br />
					<AccordionComponent
						accordionItemid="accordinAdditionIssues"
						accordionTitleId="accordionAdditonalIssuesTitle"
						transkey="settings.issueProfiles.additionalIssues"
						helperIcon="091"
						itemBody={
							<>
								<CheckBoxComponent
									id="checkboxLowBatteryWarning"
									name="low_battery_warning"
									text="Low battery warning"
									onChange={this.handleIssueAlarmInputChange}
									checked={this.state.issueProfile.low_battery_warning}
									disabled={this.state.formSettings.lowBatteryWarningCheckboxDisabled}
								/>
								<br />
								<TiltWarningComponent
									issueProfile={this.state.issueProfile}
									handleIssueAlarmInputChange={this.handleIssueAlarmInputChange}
									mode={this.state.mode}
									formSettings={this.state.formSettings}
								/>
							</>
						}
					/>
					<br />
					<AccordionComponent
						accordionItemid="accordinMappings"
						accordionTitleId="accordionMappingsTitle"
						transkey="settings.issueProfiles.mappings"
						itemBody={<SensorMappingsTable sensors={this.state.sensors} />}
					/>
				</form>
			</ContentWrapper>
		);
	}
}

export default IssueProfileComponent;
