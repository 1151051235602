import React, {MouseEvent, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Col, Row} from 'antd';
import {Accordion, AccordionItem, AccordionItemBody, AccordionItemTitle} from 'react-accessible-accordion';
import DeviationService from '../DeviationsService';
import {Trans, useTranslation} from 'react-i18next';
import AuditTrailEventTypes from '../../AuditTrail/AuditTrailEventTypes';
import ChartComponent from '../../Analysis/Chart/ChartComponent';
import SensorService from '../../Dashboard/Sensor/SensorService';
import SensorAnalysisService from '../../Analysis/SensorAnalysisService';
import DeviationTypeTie from './DeviationTypeTie';
import DeviationAcknowledgedTie from './DeviationAcknowledgedTie';
import DeviationDurationTie from './DeviationDurationTie';
import DeviationDurationOutsideLimitTie from './DeviationDurationOutsideLimitTie';
import DeviationMissingValuesTie from './DeviationMissingValuesTie';
import DeviationLowSignalTie from './DeviationLowSignalTie';
import DeviationLowBatteryTie from './DeviationLowBatteryTie';
import SensorUtils from '../../Dashboard/Sensor/SensorUtils';
import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';
import fileSaver from 'file-saver';
import Shared from '../../Shared/Shared';
import Report from '../../Shared/Components/Report';
import {Access} from '../../Infrastructure/Authorization/Components';
import {accessPermissions, userRoles} from '../../Infrastructure/Authorization/Access';
import RequestLogger from '../../Infrastructure/Requests/Logger/RequestLogger';
import {AlarmLimitBreach, Event, EventCollection, EventDetailType, EventType} from '../../../common/types';
import {ViewHeader} from '../../Common';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {Statistics} from '../../../common/types/Statistics';
import {AlarmZones} from '../../../common/constants/AlarmZones';
import StatisticsService from '../../Services/Statistics/StatisticsService';
import {ContentWrapper} from '../../Layout';
import {MeasurementsViewRow, SensorLimitsRoutineRow, SensorsAllInformationsViewRow} from '../../../common/types/DbModel';
import {EventDetailList} from '../../';
import DeviationLimitTile from './DeviationLimitTile';
import moment from 'moment';
import {useLocation, useNavigate} from 'react-router-dom-v5-compat';
import {CloseButton} from '../../Buttons';
import {DeviceUtils} from '../../../common/util';
import {SensorInfo} from 'components/Services/Sensors/SensorInfo';
import DeviationTiltAngleTie from './DeviationTiltAngleTie';
import {TiltGraph} from '../../../components/Tilt/TiltGraph';

dayjs.extend(utc);

type ChartTimeframe = {
	deviationDuration: number;
	chartStart: dayjs.Dayjs;
	chartEnd: dayjs.Dayjs;
};

export function DeviationDetail(): React.JSX.Element {
	const [t] = useTranslation();
	const navigate = useNavigate();

	const params: {id?: string; sensorId?: string} = useParams();
	const eventId: number = parseInt(params.id);

	const location = useLocation();
	const sensorId: number | null = location.state?.sensorId ?? parseInt(params.sensorId);

	const [event, setEvent] = useState<Event>();
	const [sensor, setSensor] = useState<SensorInfo>();
	const [reportLoading, setReportLoading] = useState<boolean>(false);
	const [relatedEvents, setRelatedEvents] = useState<EventCollection>();
	const [breaches, setBreaches] = useState<Array<AlarmLimitBreach>>();
	const [statistics, setStatistics] = useState<Statistics>();
	const [chartTimeframe, setChartTimeframe] = useState<ChartTimeframe>();
	const [tiltValues, setTiltValues] = useState([]);

	// Legacy types for the chart
	const [measurementsView, setMeasurementsView] = useState<MeasurementsViewRow[]>();
	const [sensorsAllInformationsView, setSensorsAllInformationsView] = useState<SensorsAllInformationsViewRow>();
	const [sensorLimitsRoutine, setSensorLimitsRoutine] = useState<SensorLimitsRoutineRow[]>();

	const chartRef = useRef(null);

	useEffect(() => {
		console.debug('Getting Deviation');
		DeviationService.getDeviation(eventId, RequestLogger.createLogData('deviation-detail', 'load-deviation', 'onLoad'))
			.then(response => {
				const event = new Event(response.data);
				setEvent(event);
			})
			.catch(function (error) {
				console.error('error ', error);
			});
	}, [eventId]);

	useEffect(() => {
		if (!sensorId) {
			return;
		}
		console.debug('Getting sensor');
		SensorService.sensor(sensorId, RequestLogger.createLogData('deviation-detail', 'load-sensor-data', 'onLoad'))
			.then(response => {
				setSensorsAllInformationsView(response.data);
				setSensor(response.data);
			})
			.catch(error => console.error(error));
	}, [sensorId]);

	useEffect(() => {
		if (!event) {
			return;
		}
		console.debug('Getting related events');
		DeviationService.getEvents(event.IncidentId, RequestLogger.createLogData('deviation-detail', 'load-incident', 'onLoad'))
			.then(function (response) {
				const relatedEvents = new EventCollection(response.data.map(e => new Event(e)));
				setRelatedEvents(relatedEvents);
			})
			.catch(function (error) {
				console.error(error);
			});
	}, [event]);

	useEffect(() => {
		if (!sensor || !chartTimeframe) {
			return;
		}

		if (event.GetEntryReason() === 'tilt_warning') {
			SensorAnalysisService.tiltValues(
				sensor.id.toString(),
				chartTimeframe.chartStart.toISOString(),
				chartTimeframe.chartEnd.toISOString(),
				RequestLogger.createLogData('deviation-details', 'load-tilt-values', 'onLoad')
			)
				.then(response => {
					setTiltValues(response);
				})
				.catch(e => console.error(e));
		} else {
			SensorAnalysisService.measurements(
				'?sensors_id=eq.' +
					sensor.id +
					'&tstamp=gte.' +
					chartTimeframe.chartStart.toISOString() +
					'&tstamp=lte.' +
					chartTimeframe.chartEnd.toISOString() +
					'&order=tstamp',
				RequestLogger.createLogData('deviation-details', 'load-measurements', 'onLoad')
			)
				.then(response => {
					const formatedMeasurements = response.data.map(m => {
						const tstampString = moment.parseZone(m.tstamp).utc().toString();
						m.tstamp = DateTimeUtils.utcOffset_date_dep(tstampString).format('YYYY-MM-DDTHH:mm:ss') + '+00:00';
						m.value = m.status ? null : m.value;
						return m;
					});

					setMeasurementsView(formatedMeasurements);
				})
				.catch(e => console.error(e));
		}
	}, [chartTimeframe]);

	useEffect(() => {
		if (!chartTimeframe || !sensor) {
			return;
		}
		console.debug('Getting Breaches');

		SensorAnalysisService.getSensorAlarmsFromRange(
			sensor.id,
			chartTimeframe.chartStart.toISOString(),
			chartTimeframe.chartEnd.toISOString(),
			RequestLogger.createLogData('deviation-details', 'load-sensor-alarm-from-range', 'onLoad')
		)
			.then(response => {
				const formatLimitAlarms = response.data.map(l => {
					const tstampStringFrom = moment.parseZone(l.valid_from).utc().toString();
					l.valid_from = DateTimeUtils.utcOffset_date_dep(tstampStringFrom).format('YYYY-MM-DDTHH:mm:ss') + '+00:00';

					const tstampStringUntil = moment.parseZone(l.valid_until).utc().toString();
					l.valid_until = DateTimeUtils.utcOffset_date_dep(tstampStringUntil).format('YYYY-MM-DDTHH:mm:ss') + '+00:00';
					return l;
				});

				setSensorLimitsRoutine(formatLimitAlarms);
				setBreaches(response.data.map(b => new AlarmLimitBreach(b)));
			})
			.catch(e => console.error(e));
	}, [chartTimeframe]);

	useEffect(() => setChartTimeframe(computeChartTimeframe(relatedEvents, sensor)), [relatedEvents]);

	useEffect(() => {
		if (!sensor || !event || !breaches || !relatedEvents) {
			return;
		}

		console.debug('Retrieving statistics');

		let delay: number = 0;
		if (event.GetEntryReason() === 'upper_limit_alarm') {
			delay = breaches[0]?.Details[0]?.UpperLimitDelay ?? 0;
		} else if (event.GetEntryReason() === 'lower_limit_alarm') {
			delay = breaches[0]?.Details[0]?.LowerLimitDelay ?? 0;
		}
		const statisticRange = getStatisticRange(
			relatedEvents.GetDeviationEnterDate(),
			relatedEvents.GetDeviationLeaveDate(),
			sensor.logging_interval,
			delay
		);

		let payload = {
			sensors_id: sensor.id,
			from: statisticRange.from,
			to: statisticRange.to,
		};

		StatisticsService.getRunStatistics(payload, RequestLogger.createLogData('deviation-details', 'load-statistics', 'onLoad'))
			.then(response => setStatistics(response.data as Statistics))
			.catch(error => console.log('error ', error));
	}, [sensor, event, breaches]);

	function getReport(e) {
		setReportLoading(true);
		DeviationService.deviationDetailReport(
			getReportPayload(),
			RequestLogger.createLogData('deviation-detail', 'create-report', 'onClick')
		)
			.then(response => {
				var contentDisposition = response.headers['content-disposition'];
				var match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
				var filename = match[1];

				fileSaver.saveAs(response.data, filename);
			})
			.catch(error => {
				console.error(error);
			})
			.finally(() => {
				setReportLoading(false);
			});

		e.preventDefault();
	}

	function getReportPayload() {
		const chartSVG = chartRef.current?.getChart().getChartHTML();

		const upperLimitDelay = breaches[0]?.Details[0]?.UpperLimitDelay ?? 0;
		const timeRange = getStatisticRange(
			relatedEvents.GetDeviationEnterDate(),
			relatedEvents.GetDeviationLeaveDate(),
			sensor.logging_interval,
			upperLimitDelay
		);

		return {
			incidentNo: event.IncidentId,
			sensor: {
				id: sensor.id,
				name: sensor.device_name,
			},
			timeFilter: {
				from: timeRange.from,
				to: timeRange.to,
			},
			svgImage: chartSVG,
		};
	}

	const getHeaderData = () => {
		// Hack to show the loading icon in the tiles
		if (!statistics || !event) {
			return {
				deviationLimitTile: {limit: undefined},
				deviationDurationTie: {duration: undefined},
				deviationDurationOutsideLimitTie: {duration: undefined},
				deviationLowBatteryTie: {batteryLevel: undefined},
				deviationTypeTie: {title: undefined},
				deviationLowSignalTie: {lowestValue: undefined},
				deviationMissingValuesTie: {missingValues: undefined},
				deviationTiltAngleTie: {tiltAngle: undefined},
			};
		}

		const {minValueDateTime, minValue} = statistics.statisticResult;
		const {maxValueDateTime, maxValue} = statistics.statisticResult;

		const affectedLimit = event?.Details?.find(d => d.EventDetailTypeId === EventDetailType.LimitZoneType)
			?.Value?.toString()
			?.toUpperCase();

		const affectedZoneResult = affectedLimit ? statistics?.resultZones[AlarmZones[affectedLimit]] : undefined;

		switch (event.GetEntryReason()) {
			case 'upper_limit_alarm':
				const maxValueDT = DateTimeUtils.addUserUTCOffsetToDateTime_date_dep(maxValueDateTime);
				return {
					deviationLimitTile: {
						affectedLimit: affectedLimit ?? 'H1',
					},
					deviationTypeTie: {
						title: 'Highest Value',
						date: DateTimeUtils.getUTCDateTimeFormat(maxValueDT),
						temperature: SensorUtils.convertTemperature(maxValue, statistics.outUnitsId),
						unit: Shared.getSensorUnit(statistics.outUnitsId),
					},
					deviationDurationTie: {
						duration: DateTimeUtils.formatDurationTime(formatSecondsInHours(chartTimeframe.deviationDuration)),
					},
					deviationDurationOutsideLimitTie: {
						duration: DateTimeUtils.formatDurationTime(
							affectedZoneResult?.durationLastActualEVENT ?? statistics.statisticResult.durationOutOfLimit
						),
					},
				};
			case 'lower_limit_alarm':
				const minValueDT = DateTimeUtils.addUserUTCOffsetToDateTime_date_dep(minValueDateTime);
				return {
					deviationLimitTile: {
						affectedLimit: affectedLimit ?? 'L1',
					},
					deviationTypeTie: {
						title: 'Lowest Value',
						date: DateTimeUtils.getUTCDateTimeFormat(minValueDT),
						temperature: SensorUtils.convertTemperature(minValue, statistics.outUnitsId),
						unit: Shared.getSensorUnit(statistics.outUnitsId),
					},
					deviationDurationTie: {
						duration: DateTimeUtils.formatDurationTime(formatSecondsInHours(chartTimeframe.deviationDuration)),
					},
					deviationDurationOutsideLimitTie: {
						duration: DateTimeUtils.formatDurationTime(
							affectedZoneResult?.durationLastActualEVENT ?? statistics.statisticResult.durationOutOfLimit
						),
					},
				};
			case 'sensor_failure_alarm':
				return {
					deviationDurationTie: {
						duration: formatMinutesInHours(chartTimeframe.deviationDuration),
					},
				};
			case 'missing_value_alarm':
				return {
					deviationMissingValuesTie: {
						missingValues: getMissingValues(sensor.logging_interval, chartTimeframe.deviationDuration),
					},
				};
			case 'radio_connection_warning':
				const value = relatedEvents
					.GetEvent(EventType.DeviationEnter)
					.Details.find(d => d.EventDetailTypeId === EventDetailType.RadioConnectionValue).Value;

				return {
					deviationLowSignalTie: {
						lowestValue: value,
					},
				};
			case 'low_battery_warning':
				const moduleSerialNumber = relatedEvents
					.GetEvent(EventType.DeviationEnter)
					.Details.find(d => d.EventDetailTypeId === EventDetailType.ModuleId)
					?.Value?.toString();

				const deviceType = relatedEvents
					.GetEvent(EventType.DeviationEnter)
					.Details.find(d => d.EventDetailTypeId === EventDetailType.ModuleType)?.Value;

				let mitigationTranslationKey = `deviations.mitigationOptions.low_battery_warning.${
					DeviceUtils.IsLiberoGx(moduleSerialNumber) ? 'LiberoGx' : 'EcologPro'
				}`;

				return {
					deviationLowBatteryTie: {
						batteryLevel: t(mitigationTranslationKey, {device: deviceType}),
					},
				};
			case 'tilt_warning': {
				const tiltAngle = relatedEvents
					.GetEvent(EventType.DeviationEnter)
					.Details.find(d => d.EventDetailTypeId === EventDetailType.TiltAngle).Value;

				return {
					deviationTiltAngleTie: {
						tiltAngle: tiltAngle,
					},
				};
			}
			default:
				return;
		}
	};

	const getMissingValues = (interval: number, duration: number) => {
		const intervalInMinutes = Math.round(interval / 60);
		return Math.round(duration / intervalInMinutes);
	};

	function getStatisticRange(startTime: dayjs.Dayjs, endTime: dayjs.Dayjs, loggingInterval: number, alarmLimitDelay: number) {
		const buffer = loggingInterval * alarmLimitDelay;

		return {
			from: startTime.subtract(buffer, 'seconds').toISOString(),
			to: endTime?.toISOString() ?? dayjs().toISOString(),
		};
	}

	function close(e: MouseEvent) {
		// If we opened this page by clicking on a link in an external application, or through a bookmark, location.key
		// will either be '' or 'default'.
		// TODO: If we click close, we should probably go back to the page where we came from and not a hardcoded url
		navigate('/deviations');
		e.preventDefault();
	}

	const formatMinutesInHours = (durationInMinutes: number) => formatSecondsInHours(durationInMinutes * 60);

	const formatSecondsInHours = (durationInSeconds: number) => {
		const pad = (num: number, size: number) => ('000' + num).slice(size * -1),
			hours = Math.floor(durationInSeconds / 60 / 60),
			minutes = Math.floor(durationInSeconds / 60) % 60,
			seconds = Math.floor(durationInSeconds - minutes * 60);
		return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
	};

	const showDeviationLimitTile = (): boolean => ['upper_limit_alarm', 'lower_limit_alarm'].includes(event?.GetEntryReason());
	const showDeviationTypeTie = (): boolean => ['upper_limit_alarm', 'lower_limit_alarm'].includes(event?.GetEntryReason());
	const showDeviationDurationTie = (): boolean =>
		['upper_limit_alarm', 'lower_limit_alarm', 'sensor_failure_alarm'].includes(event?.GetEntryReason());

	const showDeviationOutsideLimitTie = (): boolean => ['upper_limit_alarm', 'lower_limit_alarm'].includes(event?.GetEntryReason());

	const acknowledge = e => {
		navigate('/deviationAcknowledge/' + event.DeviationId, {
			state: {
				deviation: {id: event.Id},
				sensorId: sensorId,
				entryReason: event.GetEntryReason(),
			},
		});
		e.preventDefault();
	};

	return (
		<>
			<ViewHeader
				heading={
					event?.GetEntryReason() &&
					[t('deviations.reason.' + event.GetEntryReason()).toString(), sensor?.device_name, event.IncidentId.toString()]
						.filter(str => !!str) // Remove undefined and null strings (e.g. sensor?.Name)
						.join(' - ')
				}
			>
				<Col>
					<Access access={accessPermissions.deviations.child.deviationsDetail.child.createReport} roles={userRoles.default}>
						<Report onClick={getReport} loadingReport={reportLoading} />
					</Access>
				</Col>
				<Col>
					<CloseButton onClick={close} />
				</Col>
			</ViewHeader>
			<ContentWrapper>
				{event && (
					<Row style={{margin: '16px 0'}} gutter={[16, 16]}>
						{showDeviationLimitTile() && (
							<Col flex="auto">
								<DeviationLimitTile deviation_limit={getHeaderData().deviationLimitTile} />
							</Col>
						)}

						{showDeviationTypeTie() && (
							<Col flex="auto">
								<DeviationTypeTie deviation_type={getHeaderData().deviationTypeTie} />
							</Col>
						)}

						{showDeviationDurationTie() && (
							<Col flex="auto">
								<DeviationDurationTie deviation_duration={getHeaderData().deviationDurationTie} />
							</Col>
						)}

						{showDeviationOutsideLimitTie() && (
							<Col flex="auto">
								<DeviationDurationOutsideLimitTie
									deviation_outside_limit={getHeaderData().deviationDurationOutsideLimitTie}
								/>
							</Col>
						)}

						{event.GetEntryReason() == 'missing_value_alarm' && (
							<Col flex="auto">
								<DeviationMissingValuesTie deviation_missing={getHeaderData().deviationMissingValuesTie} />
							</Col>
						)}

						{event.GetEntryReason() == 'radio_connection_warning' && (
							<Col flex="auto">
								<DeviationLowSignalTie lowest_rssi_value={getHeaderData().deviationLowSignalTie} />
							</Col>
						)}

						{event.GetEntryReason() == 'low_battery_warning' && (
							<Col flex="auto">
								<DeviationLowBatteryTie low_battery={getHeaderData().deviationLowBatteryTie} />
							</Col>
						)}

						{event.GetEntryReason() == 'tilt_warning' && (
							<Col flex="auto">
								<DeviationTiltAngleTie tiltDeviation={getHeaderData().deviationTiltAngleTie} />
							</Col>
						)}

						<Col flex="auto">
							<DeviationAcknowledgedTie
								show={true}
								acknowledgeEvent={relatedEvents?.GetDeviationAcknowledgedEvent()}
								acknowledgeCallback={acknowledge}
							/>
						</Col>
					</Row>
				)}

				{sensor && event && relatedEvents && (
					<Accordion style={{margin: '16px 0'}}>
						<AccordionItem expanded>
							<AccordionItemTitle>
								<h5 className="u-position-relative">
									Chart
									<div className="accordion__arrow" role="presentation" />
								</h5>
							</AccordionItemTitle>
							<AccordionItemBody id="deviationChart">
								{event.GetEntryReason() === 'tilt_warning' ? (
									<TiltGraph
										tiltProfile={sensorsAllInformationsView.metadata.tilt_detection}
										tiltDeviations={[relatedEvents.GetEvent(EventType.DeviationEnter)].map(e => ({
											timestamp: dayjs(e.DateOccurred),
											tiltAngle: Number(e.Details.find(d => d.EventDetailTypeId === EventDetailType.TiltAngle).Value),
										}))}
										tiltValues={tiltValues}
										filterActive={false}
										handleChartSelection={() => {}}
										resetZoom={() => {}}
										forwardRef={chartRef}
									/>
								) : (
									<ChartComponent
										forwardRef={chartRef}
										sensor={sensorsAllInformationsView}
										measurements={measurementsView}
										limitAlarms={sensorLimitsRoutine}
										dateFrom={chartTimeframe?.chartStart}
										dateTo={chartTimeframe?.chartEnd}
										deviationStart={
											DateTimeUtils.utcOffset_date_dep(
												relatedEvents?.GetDeviationEnterDate()?.utc().toString()
											).format('YYYY-MM-DDTHH:mm:ss') + '+00:00'
										}
										deviationEnd={
											DateTimeUtils.utcOffset_date_dep(
												relatedEvents?.GetDeviationLeaveDate()?.utc().toString()
											).format('YYYY-MM-DDTHH:mm:ss') + '+00:00'
										}
										zoomType="false"
										outUnitsId={statistics?.outUnitsId}
										sensorLimitAlarms={[]}
										sensorIssueAlarms={[]}
										sensorReplaces={[]}
										moduleFamilyType={undefined}
										startRun={undefined}
										stopRun={undefined}
										sensorErrors={[]}
										sensorCalibrations={[]}
										occurrences={[]}
										otherOccurrences={[]}
										filterActive={false}
										resetZoom={() => {}}
									/>
								)}
							</AccordionItemBody>
						</AccordionItem>
					</Accordion>
				)}

				{relatedEvents && (
					<Accordion style={{margin: '16px 0'}}>
						<AccordionItem id="accordionSensorEvents" expanded>
							<AccordionItemTitle id="accordionSensorEventsTitle">
								<h5 className="u-position-relative">
									Events
									<div className="accordion__arrow" role="presentation" />
								</h5>
							</AccordionItemTitle>
							<AccordionItemBody>
								<Accordion>
									{relatedEvents.Array.map((event, index) => (
										<AccordionItem key={index}>
											<AccordionItemTitle className="accordion__sub__title">
												<h5 className={'u-position-relative ' + AuditTrailEventTypes[event.Type].translate}>
													{DateTimeUtils.toUserString(event.DateOccurred)}{' '}
													<Trans i18nKey={'auditTrail.events.' + AuditTrailEventTypes[event.Type].translate} />
													<div className="accordion__arrow" role="presentation" />
												</h5>
											</AccordionItemTitle>

											<AccordionItemBody>
												<EventDetailList eventDetails={event.Details} />
											</AccordionItemBody>
										</AccordionItem>
									))}
								</Accordion>
							</AccordionItemBody>
						</AccordionItem>
					</Accordion>
				)}
			</ContentWrapper>
		</>
	);
}

function computeChartTimeframe(relatedEvents: EventCollection, sensor: SensorInfo): ChartTimeframe {
	const now = dayjs();
	const deviationStart = (relatedEvents?.GetDeviationEnterDate() ?? now).utc();
	const deviationEnd = (relatedEvents?.GetDeviationLeaveDate() ?? now).utc();
	const deviationDuration = deviationEnd.diff(deviationStart, 'seconds');

	// calc chart start time: deviationStart - deviation duration
	let chartStart = deviationStart.subtract(deviationDuration, 'seconds').utc();

	// min calc
	const minDuration = sensor ? 10 * sensor.logging_interval : 0;
	let minChartStart = deviationStart.subtract(minDuration, 'seconds').utc();

	if (chartStart.isAfter(minChartStart)) {
		chartStart = minChartStart;
	}

	const chartStartUTC = chartStart.utc();
	const chartEndUTC = now.utc();

	return {
		deviationDuration: deviationDuration,
		chartStart: chartStartUTC,
		chartEnd: chartEndUTC,
	};
}

export default DeviationDetail;
