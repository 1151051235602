import request from '../Infrastructure/Requests/Request';
import Authentication from '../Infrastructure/Authentication/Authentication';

function getDeviation(id, eventData = null) {
	return request({
		url: '/deviations?id=eq.' + id,
		method: 'GET',
		headers: {Accept: 'application/vnd.pgrst.object'},
		token: Authentication.getToken(),
		eventData,
	});
}

function getAllFromIncident(id, eventData = null) {
	return request({
		url: 'deviations?incidents_id=eq.' + id,
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

function deviations_active(eventData = null) {
	return request({
		url: '/deviations_active?order=date_occurred.desc',
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

function deviations_inactive(eventData = null) {
	return request({
		url: '/deviations_inactive?order=date_occurred.desc',
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

function deviations_acknowledged(eventData = null) {
	return request({
		url: '/deviations_acknowledged',
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

function deviations_notaknowledgedalarms(eventData = null) {
	return request({
		url: '/deviations_notaknowledgedalarms',
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

function deviations_notaknowledgedwarnings(eventData = null) {
	return request({
		url: '/deviations_notaknowledgedwarnings',
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

function acknowledge_deviation(data, eventData = null) {
	return request({
		url: '/rpc/acknowledge_deviation',
		method: 'POST',
		data: data,
		token: Authentication.getToken(),
		eventData: eventData,
	});
}

function deviationDetailReport(payload, eventData = null) {
	return request({
		url: '/report/deviationDetail',
		method: 'POST',
		data: payload,
		responseType: 'blob',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/pdf',
		},
		token: Authentication.getToken(),
		eventData,
	});
}

function getEvents(incidentId, eventData = null) {
	return request({
		url: '/events?incidents_id=eq.' + incidentId + '&order=date_occurred.desc',
		method: 'GET',
		token: Authentication.getToken(),
		eventData,
	});
}

const DeviationsService = {
	getDeviation,
	getAllFromIncident,
	deviations_active,
	deviations_inactive,
	deviations_acknowledged,
	deviations_notaknowledgedalarms,
	deviations_notaknowledgedwarnings,
	acknowledge_deviation,
	deviationDetailReport,
	getEvents,
};

export default DeviationsService;
