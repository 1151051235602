import React, {ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {AuthContext} from '../../common/context';
import {PredictionService} from '../../common/services';
import {AgGridReact} from 'ag-grid-react';
import {ColDef, GridOptions} from 'ag-grid-enterprise';
import {AgGridWrapper} from '../Shared/Components';
import {formatDayJsInUserTz} from '../Infrastructure/DateTime/DateTimeUtils';
import CountStatusBar from '../SensorListView/CountStatusBar';
import dayjs from 'dayjs';
import {Link} from 'react-router-dom';
import {Paths} from '../../common/constants';
import {Card} from '../Common';
import {ErrorAlert} from '../Common/Alerts';
import {Button, Skeleton, Spin} from 'antd';
import {IPredictionWithSensor, ISensor} from '@elproag/predict';

function SensorAnalysisCellRenderer(props: Readonly<{value: ISensor}>): ReactNode {
	if (!props.value) return <>Unknown or deleted sensor</>;
	return <Link to={`${Paths.Sensor.Analysis}/${props.value.id}`}>{props.value.name}</Link>;
}

function ExportCsvButton(props: Readonly<{exportFunction: () => void}>): ReactNode {
	return (
		<div className={'ag-status-name-value'}>
			<Button onClick={props.exportFunction}>Export CSV</Button>
		</div>
	);
}

export function PredictionGrid() {
	const gridRef = useRef<AgGridReact>(null);
	const [predictions, setPredictions] = useState<IPredictionWithSensor[]>([]);
	const authContext = useContext(AuthContext);

	const [loading, setLoading] = useState(true);

	const orgUuid = authContext.Organization?.Uuid;

	useEffect(() => {
		if (orgUuid) {
			PredictionService.GetPredictions(orgUuid)
				.then(setPredictions)
				.catch(_ => ErrorAlert.fire({text: 'Failed to load predictions'}))
				.finally(() => setLoading(false));
		}
	}, [orgUuid]);

	const colDefs: ColDef<IPredictionWithSensor>[] = useMemo(
		() => [
			{
				headerName: 'Sensor Name',
				valueGetter: row => row.data.sensor,
				cellRenderer: SensorAnalysisCellRenderer,
				flex: 1,
				resizable: true,
			},
			{
				headerName: 'Serial Number',
				field: 'sensor.serialNumber',
				flex: 1,
				resizable: true,
			},
			{
				valueGetter: params => dayjs.unix(params.data.prediction?.timestampUnixUtc),
				valueFormatter: params => formatDayJsInUserTz(params.value),
				headerName: 'Started at',
				filter: 'agDateColumnFilter',
				filterParams: {
					buttons: ['reset'],
				},
				flex: 1,
			},
		],
		[]
	);

	const exportCsv = useCallback(() => {
		gridRef.current?.api.exportDataAsCsv();
	}, [gridRef.current]);

	const statusBar: GridOptions['statusBar'] = useMemo(
		() => ({
			statusPanels: [
				{statusPanel: CountStatusBar, align: 'left'},
				{statusPanel: ExportCsvButton, statusPanelParams: {exportFunction: exportCsv}, align: 'right'},
			],
		}),
		[exportCsv]
	);

	return (
		<Card title="Predictions" styles={{body: {height: '95%'}}} style={{height: '100%'}}>
			{loading ? (
				<Skeleton />
			) : (
				<AgGridWrapper>
					<AgGridReact<IPredictionWithSensor>
						rowData={predictions}
						columnDefs={colDefs}
						statusBar={statusBar}
						ref={gridRef}
						loadingOverlayComponent={Spin}
					></AgGridReact>
				</AgGridWrapper>
			)}
		</Card>
	);
}
