export enum EventDetailType {
	PropertyChange = 3,
	ErrorDetails = 4,
	EntryReason = 5,
	ModuleId = 7,
	ModuleType = 8,
	SensorName = 9,
	FullName = 10,
	RadioConnectionValue = 23,
	MessageText = 32,
	SensorLoggingInterval = 41,
	SensorId = 68,
	CalibrationExpiryModules = 87,
	BreachType = 111,
	BreachExpectedTimestampUnixUtc = 112,
	LimitZoneType = 121,
	ProfileLength = 135,
	ProfileEndSecondsUnixUtc = 136,
	TimestampFirstMeasurementSecondsUnixUtc = 137,
	TimestampLastMeasurementSecondsUnixUtc = 138,
	TimestampDelta = 139,
	OrganizationConfig = 140,
	TiltAngle = 145,
}
