import moment from 'moment/moment';
import Shared from '../../Shared/Shared';

const chartConfiguration = props => {
	const limit = props.limitAlarms ? props.limitAlarms[0]?.details[0] : undefined;
	const min = limit ? (limit.lower_limit_3 ? limit.lower_limit_3 : limit.lower_limit_2 ? limit.lower_limit_2 : limit.lower_limit) - 5 : 0;
	const max = limit
		? (limit.upper_limit_4
				? limit.upper_limit_4
				: limit.upper_limit_3
				? limit.upper_limit_3
				: limit.upper_limit_2
				? limit.upper_limit_2
				: limit.upper_limit) + 5
		: 0;

	return {
		title: {
			text: null,
		},
		lang: {
			noData: 'No data',
		},
		credits: {
			enabled: false,
		},
		exporting: {
			enabled: false,
		},
		series: [],
		chart: {
			allowChartUpdate: false,
			zoomType: props.zoomType,
			height: 400,
			backgroundColor: 'rgba(255, 255, 255, 0.1)',
			borderRadius: 0,
			spacing: [5, 20, 20, 0],
			animation: false,
			accessibility: {
				keyboardNavigation: {
					enabled: true,
				},
			},
			resetZoomButton: {
				theme: {style: {display: 'none'}},
			},
			events: {
				load: function () {
					this.series.forEach(x => {
						if (x.name === 'Deviation start' || x.name === 'Deviation end' || x.name === 'Measurement Uploads') {
							x.legendLine.attr('transform', 'translate(25,3) rotate(90)');
						}

						if (x.name.includes('Sensor Error')) {
							x.legendLine.attr('transform', 'scale(0.8, 6) translate(0, -9)');
						}
					});
				},
				redraw: function () {
					this.series.forEach(x => {
						if (x.name.includes('Sensor Error')) {
							x.legendLine.attr('transform', 'scale(0.8, 6) translate(0, -9)');
						}
					});
				},
			},
		},
		xAxis: {
			type: 'datetime',
			labels: {
				formatter: function () {
					return '<div>' + moment.utc(this.value).format('DD.MMM.YYYY HH:mm') + '</div>';
				},
				staggerLines: 2,
				y: 20,
				x: 4,
			},
			events: {
				afterSetExtremes: function (event) {
					if (typeof props.handleChartSelection === 'function') {
						if (event.trigger) {
							props.handleChartSelection(event);
						}
					}
				}.bind(this),
			},
			maxPadding: 0.03,
		},
		yAxis: [
			{
				title: {
					text: null,
				},
				labels: {
					formatter: function () {
						return this.value + ' ' + Shared.getSensorUnit(props.outUnitsId);
					},
					style: {
						backgroundColor: 'red',
					},
				},
				showEmpty: false,
				minPadding: 0.2,
				maxPadding: 0.2,
				softMin: min,
				softMax: max,
			},
			{
				title: {
					text: null,
				},
				opposite: true,
				showEmpty: false,
			},
		],
		tooltip: {
			enabled: true,
			useHTML: true,
			animation: false,
			formatter: function () {
				if (this.series.index !== 0) return false;

				const date = moment.utc(this.x).format('DD.MM.YYYY HH:mm');
				const value = `${this.y} ${Shared.getSensorUnit(props.outUnitsId)}`;

				return `<table>
							<tr><td>Date</td><td>${date}</td></tr>
							<tr><td>Value</td><td>${value}</td></tr>
						</table>`;
			},
		},
		boost: {
			useGPUTranslations: true,
			boostThreshold: 500,
			seriesThreshold: 1,
			enabled: true,
			usePreallocated: true,
		},
		plotOptions: {
			series: {
				turboThreshold: 0,
				softThreshold: false,
				animation: false,
				marker: {
					enabled: false,
					symbol: 'circle',
					states: {
						hover: {
							enabled: true,
							lineWidth: 1,
							radius: 5,
						},
					},
				},
				states: {
					hover: {
						enabled: true,
					},
				},
			},
			line: {
				animation: false,
			},
		},
	};
};

export default chartConfiguration;
