import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {Button, Col, Input, Row} from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import DeviationsService from '../DeviationsService';
import Close from '../../Shared/Components/Close';
import Message from '../../Shared/Components/Message';
import {Trans} from 'react-i18next';
import RequestLogger from '../../Infrastructure/Requests/Logger/RequestLogger';

interface DeviationAcknowledgeState {
	sensorId: string;
	id: string;
	deviation: any;
	comment: string;
	password: string;
	disable: boolean;
	entryReason: string;
}
class DeviationAcknowledge extends Component<RouteComponentProps, DeviationAcknowledgeState> {
	constructor(props) {
		super(props);

		this.state = {
			sensorId: props.location.state.sensorId,
			id: props.match.params.id,
			deviation: props.location.state.deviation,
			comment: '',
			password: '',
			disable: true,
			entryReason: props.location.state.entryReason,
		};

		this.handleInputChange = this.handleInputChange.bind(this);
	}

	handleInputChange(event) {
		const input = event.target;
		const value = input.type === 'checkbox' ? input.checked : input.value;

		this.setState(prevState => ({
			...prevState,
			[input.name]: value,
			disable: false,
		}));
	}

	acknowledge = e => {
		DeviationsService.acknowledge_deviation(
			{
				deviations_id: [Number(this.state.id)],
				comment: this.state.comment,
				pass: this.state.password,
			},
			RequestLogger.createLogData('deviation-acknowledge', 'acknowledge-deviation', 'onClick')
		)
			.then(response => {
				Message.success('Deviation acknowledged', 'Deviation successfully acknowledged');
				this.props.history.push({
					pathname: '/deviationsDetail/' + this.state.deviation.id,
					state: {sensorId: this.state.sensorId},
				});
			})
			.catch(error => Message.error('Error', 'The user credentials you entered were not valid, please try again.', error));
		e.preventDefault();
	};

	close = e => {
		this.props.history.push({
			pathname: '/deviationsDetail/' + this.state.deviation.id,
			state: {sensorId: this.state.sensorId},
		});
		e.preventDefault();
	};

	render() {
		return (
			<ContentWrapper>
				<div className="content-heading">
					<div>
						<Trans i18nKey={'deviations.reason.' + this.state.entryReason} />
					</div>
					<div className="ml-auto">
						<Close onClick={this.close} />
					</div>
				</div>
				<Row>
					<Col lg={4}>
						<p>Comment</p>
						<div className="input-group">
							<textarea
								name="comment"
								className="form-control"
								id="comment"
								rows={4}
								value={this.state.comment}
								onChange={this.handleInputChange}
							/>
						</div>
						<br />
						<div className="input-group">
							<Input
								type="password"
								id="inputPassword"
								name="password"
								placeholder="Password"
								value={this.state.password}
								onChange={this.handleInputChange}
							/>
							{'\u00A0'}
							<Button color="primary" size="md" onClick={this.acknowledge} disabled={this.state.disable}>
								Acknowledge
							</Button>
						</div>
					</Col>
				</Row>
			</ContentWrapper>
		);
	}
}

export default DeviationAcknowledge;
