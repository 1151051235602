import React from 'react';
import {ContentWrapper, ViewWrapper} from '../Layout';
import {ViewHeader} from '../Common';
import {useTranslation} from 'react-i18next';
import {PredictiveSettingsForm} from './PredictiveSettingsForm';

export function PredictiveSettings() {
	const [t] = useTranslation();
	const translate = (key: string): string => t(`settings.predictive.${key}`).toString();

	return (
		<ViewWrapper>
			<ViewHeader heading={translate('title')}></ViewHeader>
			<ContentWrapper>
				<PredictiveSettingsForm />
			</ContentWrapper>
		</ViewWrapper>
	);
}
