import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import {Tile as OlLayerTile, Vector as OlLayerVector} from 'ol/layer';
import {TileJSON, Vector as OlSourceVector} from 'ol/source';
import {Attribution, defaults as defaultControls} from 'ol/control';
import {fromLonLat} from 'ol/proj';
import C2S from 'canvas2svg';
import {AddClusterFeatures, InitGeoCluster} from './Utils/GeoCluster';
import GeoTooltip from './Utils/GeoTooltip';
import {GeoPolyline} from './Utils/GeoPolyline';
import DateTimeUtils from '../../Infrastructure/DateTime/DateTimeUtils';
import sensorUtils from '../../Dashboard/Sensor/SensorUtils';
import SensorAnalysisService from '../SensorAnalysisService';
import {RequestLogger} from '../../Infrastructure/Requests/Logger/';
import SystemSettings from '../../Infrastructure/Settings/SystemSettings';

import 'ol/ol.css';
import {SensorsAllInformationsViewRow} from '../../../common/types/DbModel';
import pubsub from 'pubsub-js';

export interface MapComponentProps {
	sensor: SensorsAllInformationsViewRow;
	dateFrom: string;
	dateTo: string;
}

export interface MapComponentRef {
	getMap: () => string;
}

export const MapComponent = forwardRef<MapComponentRef, MapComponentProps>((props: Readonly<MapComponentProps>, ref) => {
	const {sensor, dateFrom, dateTo} = props;

	const [geoData, setGeoData] = useState(null);
	const [olMap, setOlMap] = useState(null);
	const [vectorLayer, setVectorLayer] = useState(null);
	const [clusterLayer, setClusterLayer] = useState(null);
	const mapRef = useRef(undefined);
	const tooltipRef = useRef();
	const [geoDataSubscriptionToken, setGeoDataSubscriptionToken] = useState<string>(null);

	const newGeoDataTopic = 'geo_data_new/' + sensor.id;

	useEffect(() => {
		const token = pubsub.subscribe(newGeoDataTopic, (_topic, data) => newGeoData(data));
		setGeoDataSubscriptionToken(token);

		return () => {
			pubsub.unsubscribe(geoDataSubscriptionToken);
		};
	}, []);

	const newGeoData = newGeoPoint => {
		setGeoData(geoData.concat(newGeoPoint));
	};

	// Returns the map as SVG
	const getMap = useCallback(() => {
		let mapCanvasEl = document.createElement('canvas');

		if (olMap === null) {
			return null;
		}

		let size = olMap.getSize();
		mapCanvasEl.width = size[0];
		mapCanvasEl.height = size[1];

		let mapContext = mapCanvasEl.getContext('2d');
		let c2sContext = new C2S(size[0], size[1]);

		Array.prototype.forEach.call(document.querySelectorAll('.ol-layer canvas'), canvas => {
			if (canvas.width > 0) {
				const opacity = canvas.parentNode.style.opacity;
				mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);

				const transform = canvas.style.transform;
				const matrix = transform
					.match(/^matrix\(([^(]*)\)$/)[1]
					.split(',')
					.map(Number);

				mapContext.setTransform(...matrix);
				mapContext.drawImage(canvas, 0, 0);
			}
		});

		c2sContext.drawImage(mapCanvasEl, 0, 0);
		return c2sContext.getSerializedSvg(true);
	}, [olMap]);

	useImperativeHandle(
		ref,
		() => {
			return {
				getMap,
			};
		},
		[getMap]
	);

	// Effect to load and refresh the path when date range changes
	useEffect(() => {
		SensorAnalysisService.getGeoDatas(
			sensor.id.toString(),
			dateFrom,
			dateTo,
			RequestLogger.createLogData('geo-data', 'load-geo-data', 'onLoad')
		).then(({data}) => {
			data.forEach(d => {
				d.tstamp = DateTimeUtils.utcOffset_date_dep(d.tstamp).format('DD.MMM.YYYY HH:mm');

				if (!d.mesurement) d.mesurement = {};

				// used for csv export only
				d.mesurement.tstampOriginal = DateTimeUtils.utcOffset_date_dep(d.mesurement.tstamp);

				d.mesurement.tstamp = !d.mesurement.tstamp
					? 'n.a.'
					: DateTimeUtils.utcOffset_date_dep(d.mesurement.tstamp).format('DD.MMM.YYYY HH:mm');
				d.mesurement.value = !d.mesurement.value
					? 'n.a.'
					: `${sensorUtils.convertTemperature(d.mesurement.value, sensor.out_units_id)} ${sensorUtils.getSensorUnitType(
							sensor.out_units_id
					  )}`;
			});

			setGeoData(data);
		});
	}, [sensor.id, dateFrom, dateTo]);

	// Effect to init OSM map & The Tooltip
	useLayoutEffect(() => {
		const shouldInitMap = mapRef.current !== null && olMap === null;
		if (mapRef.current === null) setOlMap(null);
		if (!shouldInitMap) return;

		const endPoint = geoData !== null && geoData.length > 0 ? geoData[geoData.length - 1] : {long: 0, lat: 0};

		// SetTimeout: To run when the current stack is cleared
		// requestAnimationFrame: To run before the next render
		setTimeout(() =>
			requestAnimationFrame(() => {
				if (mapRef.current === null) return;
				mapRef.current.innerHTML = '';
				const attribution = new Attribution({collapsible: true});
				const mapUrl = SystemSettings.getConfig().map_link;
				const map = new OlMap({
					target: mapRef.current,
					layers: [
						new OlLayerTile({
							source: new TileJSON({
								url: mapUrl,
								tileSize: 512,
								crossOrigin: 'anonymous',
							}),
						}),
					],
					controls: defaultControls({attribution: false}).extend([attribution]),
					view: new OlView({
						center: fromLonLat([endPoint.long, endPoint.lat]),
						maxZoom: 20,
						zoom: 9,
					}),
				});
				setOlMap(map);

				// Init Tooltip
				const tooltip = GeoTooltip(map, tooltipRef.current);
				map.addOverlay(tooltip);
			})
		);
	}, [geoData]);

	// Effect to initialize Markers & the Polyline
	useLayoutEffect(() => {
		if (!olMap) return;
		//const endPoint = geoData !== null && geoData.length > 0 ? geoData[geoData.length - 1] : {long: 0, lat: 0};

		let routePoints = [];
		if (geoData !== null) {
			geoData.forEach((point, _index) => {
				routePoints.push([point.long, point.lat]);
			});
		}

		const [polylineFeature] = GeoPolyline(routePoints);
		const [_clusterLayer, clusterSource] = InitGeoCluster(olMap);

		const _sourceVector = new OlSourceVector({
			features: [polylineFeature],
		});
		const _vectorLayer = new OlLayerVector({
			source: _sourceVector,
		});

		if (!!vectorLayer) olMap.removeLayer(vectorLayer);
		if (!!clusterLayer) olMap.removeLayer(clusterLayer);

		olMap.addLayer(_vectorLayer);
		setVectorLayer(_vectorLayer);

		olMap.addLayer(_clusterLayer);
		setClusterLayer(_clusterLayer);

		if (geoData !== null) AddClusterFeatures(geoData, clusterSource);

		// Fit Features on the map view
		let vectorExtent = _sourceVector.getExtent();
		if (!!vectorExtent && !(vectorExtent.includes(Infinity) || vectorExtent.includes(-Infinity))) {
			olMap.getView().fit(vectorExtent);

			const zoomLevel = olMap.getView().getZoom() > 15 ? 15 : olMap.getView().getZoom() - 0.25;
			olMap.getView().setZoom(zoomLevel);
		}
	}, [olMap, geoData]);

	return (
		<div className="ol_map-container">
			{geoData !== null && geoData.length <= 0 ? (
				<p className="map_text">No Data</p>
			) : (
				<div ref={mapRef} className="ol_map">
					<div ref={tooltipRef} className="ol_map-popuap"></div>
				</div>
			)}
		</div>
	);
});
